import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { generateSignedUrl } from "../../utils/getPreSignedURL";
import useSanitizeContent from "../../utils/useSanitizeContent";
import useThemeColor from "../../utils/useTheme";
import "./AdminPortal.css";
import { useDispatch } from "react-redux";
import { editUser, getCurrentUser } from "../../features/auth/authSlice";

const isValidUrl = (url) => {
  return /^(https?:\/\/|www\.)/.test(url);
};

const AdminPortal = ({ value: htmlValue, userId, checkNotesReminder }) => {
  const [value, setValue] = useState("");
  const styles = useThemeColor();
  const dispatch = useDispatch();
  useEffect(() => {
    if (checkNotesReminder) {
      dispatch(editUser({ formData: { checkNotesReminder: true }, userId }));
      dispatch(getCurrentUser());
    }
  }, [userId, dispatch]);
  const updateImageSources = useCallback(
    async (html, color) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const images = Array.from(doc.querySelectorAll("img"));

      for (let img of images) {
        const fileName = img.getAttribute("src"); // Assuming the src contains the filename

        if (fileName && !fileName.startsWith("http")) {
          try {
            const signedUrl = await generateSignedUrl(
              fileName,
              process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
            );

            // Replace filename with the signed URL in the img tag
            img.src = signedUrl;
          } catch (error) {
            console.error("Error generating signed URL:", error);
          }
        }
        if (!img.hasAttribute("width")) {
          img.setAttribute("width", "100%");
        }
        if (!img.hasAttribute("height")) {
          img.setAttribute("height", "100%");
        }
      }

      const links = Array.from(doc.querySelectorAll("a"));
      links.forEach((link) => {
        link.target = "_blank";
        let href = link.getAttribute("href");
        link.style.background = ""; // Clear background
        link.style.color = ""; //
        if (href) {
          if (href.startsWith("www.")) {
            link.setAttribute("href", `https://${href}`);
          } else if (!isValidUrl(href)) {
            // Optionally prepend a default protocol (http or https)
            link.setAttribute("href", `https://${href}`);
          }
        }
      });
      const paragraphs = [
        ...Array.from(doc.querySelectorAll("span")),
        ...Array.from(doc.querySelectorAll("strong")),
        ...Array.from(
          doc.querySelectorAll(["h1", "h2", "h3", "h4", "h5", "h6"])
        )
      ];
      paragraphs.forEach((p) => {
        p.style.background = ""; // Clear background
        p.style.color = color; // Clear color
      });

      const allParas = Array.from(doc.querySelectorAll("p"));
      let consecutiveJustifyBrs = []; // Array to track sequences of <p> elements

      allParas.forEach((p, index) => {
        // Check if it's a target <p> element
        if (
          p.classList.contains("ql-align-justify") &&
          p.innerHTML.trim() === "<br>"
        ) {
          consecutiveJustifyBrs.push(p);
        } else {
          // If the sequence ends, check its length and remove if needed
          if (consecutiveJustifyBrs.length > 7) {
            const lastFourElements = consecutiveJustifyBrs.slice(-4);
            lastFourElements.forEach((element) => element.remove());
          }
          // Reset the sequence tracking array
          consecutiveJustifyBrs = [];
        }
        p.style.color = color;
      });

      // Handle any remaining sequence at the end of the loop
      if (consecutiveJustifyBrs.length > 7) {
        const lastFourElements = consecutiveJustifyBrs.slice(-4);
        lastFourElements.forEach((element) => element.remove());
      }
      // Set the updated HTML back to the editor
      setValue(doc.body.innerHTML);
    },
    [setValue]
  );

  useEffect(() => {
    const initialHtml = htmlValue || "<p>No content available.</p>";
    updateImageSources(initialHtml, styles.color);
  }, [updateImageSources, htmlValue]);

  const sanitizedContent = useSanitizeContent(value);

  return (
    <Box
      sx={{ padding: { sm: 0, md: "10px 25px" } }}
      display={"flex"}
      gap="10px"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {sanitizedContent || sanitizedContent !== "undefined" ? (
        <div
          className="preview-html"
          style={{
            marginTop: "10px",
            ".ql-align-center": {
              display: "flex",
              justifyContent: "center"
            },
            p: {
              color: `${styles.color} !important`
            },
            h1: {
              color: `${styles.color} !important`
            }
          }}
          dangerouslySetInnerHTML={{
            __html: sanitizedContent ? sanitizedContent : <div />
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AdminPortal;
