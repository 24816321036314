import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Fade,
  IconButton
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { getConnectedAccountURL } from "../../api/authApi";

const ConnectProfileModal = ({ admin }) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (
      admin?.referenceData &&
      !admin?.referenceData.connectAccountLink &&
      admin?.referenceData?.stripeAccountId &&
      admin?.userType !== "Company"
    ) {
      getConnectedAccountURL(admin?.referenceData?.stripeAccountId).then(
        (item) => {
          setUrl(item.remediationLink);
          setOpen(true);
        }
      );
    }
  }, [admin?.referenceData, admin?.userType]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenLink = () => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 3,
            p: "40px 60px 0 60px",
            textAlign: "center",
            background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
            border: "1px solid #ddd",
            overflow: "hidden"
          }}
        >
          {/* Close Icon */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "gray"
            }}
          >
            <Close />
          </IconButton>

          {/* Title */}
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            Complete Your Profile
          </Typography>
          <br />

          {/* Description */}
          <Typography
            variant="body1"
            sx={{ mb: 3, color: "#555", lineHeight: 1.5 }}
          >
            To ensure smooth payouts and access all features, we need you to
            complete your stripe profile.
            <br />
            <br />
            This process is quick and helps us verify your details.
            <br />
            Click the button below to get started.
          </Typography>

          {/* Call to Action */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenLink}
            sx={{
              width: "100%",
              py: 1,
              mb: 2,
              mt: 4,
              fontSize: "1rem",
              textTransform: "capitalize"
            }}
          >
            Complete
          </Button>

          {/* Warning Text */}
          <Typography
            variant="caption"
            sx={{ display: "block", color: "#888", mb: 2 }}
          >
            *Profile completion is required to getting your payouts.
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ConnectProfileModal;
