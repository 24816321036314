import createApi from "../utils/axiosInstance";

export const getAllUsers = async (currentTab, page, limit, search) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/user/get-all-users?page=${page}&limit=${limit}&search=${search}${
        currentTab ? `&type=${currentTab}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllCoupons = async () => {
  try {
    const api = createApi(true);
    const response = await api.get(`/payment/coupon/get-coupons`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteCouponHandler = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/payment/coupon/remove-coupon/${data.id}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Deleting coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
export const addnewCoupon = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/payment/coupon/add-coupon`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Adding coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
export const editCouponHandler = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.put(
      `/payment/coupon/edit-coupon/${data.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Editing coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getUsersByType = async (page, limit, search) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/user/get-admin-user-list?page=${page}&limit=${limit}&search=${search}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateCode = async (code, id) => {
  try {
    const api = createApi(true);
    const response = await api.put(`/admin/user/update-code/${id}`, { code });
    return response.data;
  } catch (error) {
    console.error(
      "Completing profile failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

export const deleteUserHandler = async (userId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(`/admin/user/delete-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
