const convertItemQuantityToGrams = (item) => {
  let quantityInGrams;

  // Conversion factors
  const conversions = {
    grams: 1,
    ounces: 28.35,
    cups: 130,
    palms: 85,
    pounds: 453.6,
    mls: 1
  };

  // Check for exceptions
  const exceptions = {
    leafygreen: 25,
    potato: 250
  };

  // Determine the base conversion based on unit
  if (item.unit === "grams") {
    quantityInGrams = item.quantity; // No conversion needed
  } else if (item.unit === "gmp") {
    quantityInGrams = item.quantity; // No conversion neede
  } else if (item.unit === "gmw") {
    quantityInGrams = item.quantity; // No conversion neede
  } else if (item.unit === "ounces") {
    quantityInGrams = item.quantity * conversions.ounces;
  } else if (item.unit === "cups") {
    // Check if item name has a specific exception
    quantityInGrams = exceptions[item.name]
      ? item.quantity * exceptions[item.name]
      : item.quantity * conversions.cups;
  } else if (item.unit === "palms") {
    quantityInGrams = item.quantity * conversions.palms;
  } else if (item.unit === "pounds") {
    quantityInGrams = item.quantity * conversions.pounds;
  } else if (item.unit === "mls") {
    quantityInGrams = item.quantity * conversions.mls;
  } else {
    throw new Error(`Unknown unit: ${item.unit}`);
  }

  return { ...item, quantity: Math.round(quantityInGrams), unit: "grams" };
};

const getTotalWeight = (allItems) => {
  return allItems?.reduce(
    (prev, curr) => prev + convertItemQuantityToGrams(curr).quantity,
    0
  );
};
export { convertItemQuantityToGrams, getTotalWeight };
