import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../features/auth/authSelectors";
import { getCurrentUser } from "../../features/auth/authSlice";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  // const expirationTime = localStorage.getItem("expirationTime");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  if (!isAuthenticated || !localStorage.getItem("adminToken")) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
