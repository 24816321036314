import React, { Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import RichTextEditor from "./RichTextEditor";
import { selectCurrentSuperAdmin } from "../../features/auth/authSelectors";
import { getCurrentUser, updateAdminData } from "../../features/auth/authSlice";
import { deleteFile } from "../../utils/deleteFile";
import { dataURLtoFile } from "../../utils/dataURLtoFile";
import { uploadFile } from "../../utils/uploadFile";

const Notes = () => {
  const admin = useSelector(selectCurrentSuperAdmin);
  const defaultValue = admin?.adminNotes;
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const images = Array.from(doc.querySelectorAll("img"));

    const prevUrls = new Set();
    if (defaultValue) {
      const prevDoc = parser.parseFromString(defaultValue, "text/html");
      const prevImages = Array.from(prevDoc.querySelectorAll("img"));
      for (let prevImg of prevImages) {
        const prevSrc = prevImg.getAttribute("src");
        if (prevSrc) {
          prevUrls.add(prevSrc);
        }

        if (!value?.includes(prevSrc) && !prevSrc.startsWith("http")) {
          try {
            await deleteFile(
              prevSrc,
              process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
            );
          } catch (error) {
            console.error("Error deleting previous image:", error);
          }
        }
      }
    }
    for (let img of images) {
      let src = img.getAttribute("src");
      const originalSrc = src.split("?")[0];
      const fileName = originalSrc ? originalSrc?.split("/")?.at(-1) : "";

      if (src.includes(fileName)) {
        if (prevUrls.has(fileName)) {
          img.setAttribute("src", fileName);
          continue;
        }
      }

      // Handle new base64 images
      if (src.startsWith("data:image")) {
        const file = dataURLtoFile(src, `image-${Date.now()}.png`);
        const uniqueFileName = `${Date.now()}-${file.name}`;
        try {
          await uploadFile(
            file,
            uniqueFileName,
            process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
          );

          img.setAttribute("src", uniqueFileName);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    }

    dispatch(updateAdminData({ adminNotes: doc.body.innerHTML }));
    dispatch(getCurrentUser());
  };
  return (
    <Box>
      <Box width={"100%"} display="flex" justifyContent="flex-end">
        <Button
          sx={{
            fontSize: "14px",
            padding: "16px 24px",
            background: "#38B65B",

            border: "1px solid #38B65B",
            color: "#fff",
            fontWeight: 700,
            textTransform: "none",
            height: "40px" // Ensure button height matches others
          }}
          onClick={handleSubmit}
          variant="outlined"
        >
          Update
        </Button>
      </Box>
      <Box width={"100%"}>
        <Suspense fallback={<div>Loading...</div>}>
          <RichTextEditor
            isLargeEditorNeeded
            defaultValue={defaultValue}
            value={value}
            setValue={setValue}
          />
        </Suspense>
      </Box>
    </Box>
  );
};

export default Notes;
