// modSelectors.js

// export const selectLevelData = (state) => state.diary.availableLevels;
export const selectAllDiariesData = (state) => state.diary.allDiaries;
export const selectAlOptions800g = (state) => state.diary.options800gms;
export const selectAllOptionsProtein = (state) => state.diary.optionsProteins;
export const selectAllDiaryLevels = (state) => state.diary.availableDiaryLevels;
export const selectAllDiaryStats = (state) => state.diary.stats;
export const selectAllDiaryOutOf800g = (state) => state.diary.outOf800g;
export const selectLeaderboardData = (state) => state.diary.leaderboard;
export const selectAllDiaryOutOfProtein = (state) => state.diary.outOfProtein;
