import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Avatar,
  styled,
  Snackbar,
  Alert,
  InputAdornment
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { useDispatch } from "react-redux";
import {
  editUser,
  getCurrentUser,
  updateAdminData
} from "../../features/auth/authSlice";
import { uploadFile } from "../../utils/uploadFile";
import { getUniqueSuffix } from "../../utils/getUniqueSuffix";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useSignedUrl from "../../utils/useSignedUrl";

// Styled Components
const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  marginTop: theme.spacing(5)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-22px",
  color: "#14142B",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#1C1C1C"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#D1D5DB"
    },
    "&:hover fieldset": {
      borderColor: "#1C1C1C"
    },
    "&.Mui-focused fieldset": {
      color: "#1C1C1C",
      borderColor: "#D1D5DB"
    }
  }
}));

const EditAdmin = ({ isSuperAdmin, role = "", editData, open, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    name: "",
    email: "",
    password: "",
    profilePic: null,
    [`${role?.toLowerCase()}Name`]: ""
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    name: false,
    email: false,
    password: false
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    } else if (name === "password") {
      setFormErrors({ ...formErrors, password: !validatePassword(value) });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    setFormData((prev) => ({
      ...prev,
      profilePic: file
    }));
  };

  const imgSrc = useSignedUrl(
    editData?.profilePic,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  const handleSubmit = async (e) => {
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    const { fullName, email, password, profilePic } = formData;
    const uniqueFileName = getUniqueSuffix(formData.profilePic?.name);

    const mainData = {
      fullName: fullName,
      email,
      ...(password ? { password } : {}),
      ...(profilePic ? { profilePic: uniqueFileName } : {}),
      [`${role.toLowerCase()}Name`]: formData?.[`${role.toLowerCase()}Name`]
    };

    e.preventDefault();
    const { payload } = await dispatch(
      isSuperAdmin
        ? updateAdminData({
            name: formData.name,
            email: formData.email,
            ...(password ? { password } : {}),
            ...(profilePic ? { profilePic: uniqueFileName } : {})
          })
        : editUser({ formData: mainData, userId: editData?.userId })
    );
    if (!payload.error) {
      if (profilePic)
        await uploadFile(
          profilePic,
          uniqueFileName,
          process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
        );
      handleReset();
      dispatch(getCurrentUser());
    } else {
      setSnackbarMessage(payload.error || "Editing details failed.");
      setOpenSnackbar(true);
    }
  };

  const fileInputRef = useRef();
  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleReset = () => {
    onClose();
    setFormData({
      fullName: "",
      email: "",
      password: "",
      profilePic: null
    });
    setFormErrors({
      fullName: false,
      email: false,
      password: false
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      fullName: editData?.fullName,
      [`${role.toLowerCase()}Name`]:
        editData?.referenceData?.[`${role.toLowerCase()}Name`],
      email: editData?.email,
      name: editData?.name
    });
  }, [editData]);

  return (
    <Dialog open={open} onClose={handleReset} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          {editData ? "Edit" : "Add"} Details
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Box>
          <input
            ref={fileInputRef}
            id="upload-input"
            type="file"
            accept=".png, .jpg, .jpeg"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {formData.profilePic ? (
            <Box position="relative">
              <label htmlFor="upload-input">
                <Avatar
                  alt="Profile Pic"
                  src={URL.createObjectURL(formData.profilePic)}
                  sx={{
                    width: 200,
                    height: 200,
                    bgcolor: "#f0f0f0",
                    color: "#bdbdbd",
                    mb: 2
                  }}
                />
                <IconButton
                  onClick={handleEditClick}
                  sx={{
                    position: "absolute",
                    bottom: 20, // Adjust this value to move the icon slightly outside the avatar if desired
                    right: 25, // Adjust this value to move the icon slightly outside the avatar if desired
                    backgroundColor: "#38B65B",
                    borderRadius: "50%",
                    height: 15,
                    width: 15,
                    border: "1px solid #38B65B",
                    fontSize: 24, // Adjust the size as needed
                    padding: "2px",
                    svg: {
                      path: {
                        fill: "#fff"
                      }
                    }
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </label>
            </Box>
          ) : editData?.profilePic ? (
            <Box
              onClick={() => document.getElementById("upload-input").click()}
              component="img"
              src={imgSrc}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: 200,
                height: 200,
                borderRadius: "50%",
                position: "relative",
                cursor: "pointer"
              }}
            />
          ) : (
            <Box
              onClick={() => document.getElementById("upload-input").click()}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: 200,
                height: 200,
                border: "2px dashed #D1D5DB",
                borderRadius: "50%",
                position: "relative",
                cursor: "pointer",
                bgcolor: "#F9FAFB"
              }}
            >
              <CameraIcon />
              <Typography fontWeight="bold" color="#4B5563" mt={1}>
                Tap to upload
              </Typography>
              <Typography fontSize="12px" color="#6B7280">
                (Supported file PNG, JPEG)
              </Typography>
            </Box>
          )}
        </Box>
        {isSuperAdmin ? (
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="name">Name</StyledInputLabel>
            <StyledTextField
              fullWidth
              name="name"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
              required
              error={formErrors.name}
              helperText={formErrors.name && "Name is required"}
            />
          </StyledTextFieldContainer>
        ) : (
          <>
            <StyledTextFieldContainer>
              <StyledInputLabel htmlFor="fullName">Name</StyledInputLabel>
              <StyledTextField
                fullWidth
                name="fullName"
                placeholder="Enter name"
                value={formData.fullName}
                onChange={handleChange}
                required
                error={formErrors.fullName}
                helperText={formErrors.fullName && "Name is required"}
              />
            </StyledTextFieldContainer>{" "}
            <StyledTextFieldContainer>
              <StyledInputLabel
                htmlFor={[`${role.toLowerCase()}Name`]}
                sx={{ textTransform: "capitalize" }}
              >
                {role} Name
              </StyledInputLabel>
              <StyledTextField
                fullWidth
                name={[`${role.toLowerCase()}Name`]}
                placeholder={`Enter ${role} name`}
                value={formData[`${role.toLowerCase()}Name`]}
                onChange={handleChange}
                required
              />
            </StyledTextFieldContainer>
          </>
        )}

        <StyledTextFieldContainer>
          <StyledInputLabel htmlFor="email">Email Address</StyledInputLabel>
          <StyledTextField
            fullWidth
            name="email"
            placeholder="Enter email address"
            value={formData.email}
            onChange={handleChange}
            required
            error={formErrors.email}
            helperText={formErrors.email && "Enter a valid email address"}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer style={{ marginBottom: 8 }}>
          <StyledInputLabel htmlFor="password">Password</StyledInputLabel>
          <StyledTextField
            fullWidth
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
            error={formErrors.password}
            helperText={
              formErrors.password &&
              "Password must be 8 characters long: Contains 1 upper, 1 lower case, 1 symbol and 1 digit"
            }
          />
        </StyledTextFieldContainer>
      </DialogContent>
      <DialogActions style={{ marginTop: 2, padding: 24 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{
            textTransform: "none",
            backgroundColor: "#38B65B",
            color: "#fff",
            ":disabled": {
              backgroundColor: "#D7F0DE",
              color: "#9CDBAD"
            }
          }}
          disabled={
            (isSuperAdmin ? !formData.name : !formData.fullName) ||
            !formData.email
          }
        >
          Update
        </Button>
      </DialogActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default EditAdmin;
