import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as ModIcon } from "../../assets/mod.svg";
import { ReactComponent as TrainingIcon } from "../../assets/training.svg";
import { ReactComponent as PaymentIcon } from "../../assets/dollar.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Badge, Input, Menu, MenuItem, Tooltip } from "@mui/material";
import Users from "../Tabs/Users";
import Mod from "../Tabs/MOD";
import Training from "../Tabs/Training";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../features/auth/authSlice";
import getInitials from "../../utils/getInitials";
import {
  AdminPanelSettings,
  ArrowBack,
  Description,
  Discount
} from "@mui/icons-material";
import useSignedUrl from "../../utils/useSignedUrl";
import { updateCode } from "../../api/userApi";
import ConnectProfileModal from "../Modals/ConnectLinkModal";
import EditAdmin from "../Modals/EditAdmin";
import Notes from "../Extra/Notes";
import AdminPortal from "../Tabs/AdminPortal";
import SuperAdminHome from "../Tabs/SuperAdminHome";
import AdminHome from "../Tabs/AdminHome";
import CouponManagement from "../Extra/Coupons";

const drawerWidth = 265;
const StyledPaymentIcon = styled(PaymentIcon)({
  width: 22,
  height: 17,
  "& path": {
    fill: "#9CA3AF" // Ensure you target the path element correctly
  }
});
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 24px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  height: "90px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(4, 1),
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  ...(!open && {
    marginLeft: `calc(${theme.spacing(7)} + 10px)`,
    width: `calc(100% - calc(${theme.spacing(7)} + 33px))`
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth - 20,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "visible",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      overflow: "visible"
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      overflow: "visible"
    }
  })
}));

const AdminDashboard = () => {
  const currentAdmin = useSelector((state) => state.adminauth.admin);
  const [open, setOpen] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState("Users");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAddingMeal, setIsAddingMeal] = React.useState(false);
  const [isAddingTraining, setIsAddingTraining] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    setCode(currentAdmin?.referenceData?.code || "");
  }, [currentAdmin]);
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy code: ", err);
    }
  };

  const handleBlur = async () => {
    try {
      if (isEditing) {
        // Call API to update the value
        const response = await updateCode(code, currentAdmin?.referenceId); // Replace with your API call
        if (response.message) {
          setIsEditing(!isEditing); // Toggle edit mode
        }
      } else {
        setIsEditing(!isEditing); // Toggle edit mode
      }
    } catch (error) {
      console.error("Failed to update code:", error);
    }
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value); // Update code when editing
  };

  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await dispatch(logoutUser());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Home":
        return <SuperAdminHome />;
      case "MOD":
        return (
          <Mod isAddingMeal={isAddingMeal} setIsAddingMeal={setIsAddingMeal} />
        );
      case "Users":
        return <Users />;
      case "Programs":
        return (
          <Training
            isAddingTraining={isAddingTraining}
            setIsAddingTraining={setIsAddingTraining}
          />
        );
      case "Admin Notes":
        return <Notes />;
      case "Coupons":
        return <CouponManagement />;
      default:
        return <Typography>Default content</Typography>;
    }
  };

  const adminRenderContent = () => {
    switch (activeTab) {
      case "Home":
        return <Typography>Home content goes here</Typography>;
      case "Users":
        if (!currentAdmin?.activeSubcription) return <></>;
        return <Users isAdmin={false} />;
      case "Billing":
        return <AdminHome />;
      case "Admin Portal":
        if (!currentAdmin?.activeSubcription) return <></>;
        return (
          <AdminPortal
            userId={currentAdmin?.userId}
            value={currentAdmin?.adminNotes}
            checkNotesReminder={currentAdmin?.checkNotesReminder}
          />
        );
      default:
        return <Typography>Default content</Typography>;
    }
  };

  const menuItems = !currentAdmin?.adminUserId
    ? [
        { text: "Home", icon: <HomeIcon /> },
        { text: "Users", icon: <UserIcon /> },
        {
          text: "Billing",
          icon: <StyledPaymentIcon />
        },
        {
          text: "Admin Portal",
          icon: <AdminPanelSettings sx={{ color: "#9ca3af" }} />
        }
      ]
    : [
        { text: "Home", icon: <HomeIcon /> },
        { text: "MOD", icon: <ModIcon /> },
        { text: "Users", icon: <UserIcon /> },
        { text: "Programs", icon: <TrainingIcon /> },
        {
          text: "Admin Notes",
          icon: <Description sx={{ color: "#9ca3af" }} />
        },
        { text: "Coupons", icon: <Discount sx={{ color: "#9ca3af" }} /> }
      ];

  const imgSrc = useSignedUrl(
    currentAdmin?.profilePic,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );
  const counts = useSelector((state) => state?.users?.pendingMealsCount) || 0;
  return (
    <Box display="flex">
      <CssBaseline />
      <AppBar open={open}>
        <Toolbar
          sx={{
            background: "#F9FAFB",
            justifyContent: "space-between",
            height: "95px"
          }}
        >
          <Box display="flex" alignItems="center" gap="10px">
            {isAddingMeal && activeTab === "MOD" && (
              <ArrowBack
                onClick={(e) => {
                  setIsAddingMeal(false);
                }}
                sx={{ color: "#374151", cursor: "pointer" }}
              />
            )}{" "}
            <Typography
              variant="h5"
              fontWeight="bold"
              color="black"
              noWrap
              component="div"
            >
              {activeTab === "MOD" ? "Meal Of The Day" : activeTab}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            {!currentAdmin?.adminUserId &&
              currentAdmin?.referenceData?.code && (
                <Box display={"flex"} alignItems="center" gap={1}>
                  <Typography color="#111">
                    {currentAdmin?.userType} Code :{" "}
                  </Typography>
                  <Box
                    sx={{
                      border: "1px solid #d1d5db", // Light gray border
                      borderRadius: "12px", // Rounded corners
                      padding: "12px", // Consistent padding
                      backgroundColor: "#f9fafb", // Subtle background
                      fontFamily: "monospace",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Modern shadow
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        borderColor: "#6366f1", // Highlight border on hover
                        backgroundColor: "#eef2ff" // Light background on hover
                      },
                      display: "flex", // Align content in a row
                      alignItems: "center", // Center align vertically
                      gap: "16px" // Space between elements
                    }}
                  >
                    {/* Editable or Static Code */}
                    {isEditing ? (
                      <Input
                        onBlur={handleBlur}
                        value={code}
                        onChange={handleCodeChange}
                        variant="outlined"
                        rows={2}
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#ffffff",
                            borderRadius: "6px"
                          }
                        }}
                      />
                    ) : (
                      <Box
                        component="pre"
                        sx={{
                          margin: 0,
                          flexGrow: 1, // Allow text to take available space
                          color: "#374151", // Neutral dark text
                          padding: "8px 0"
                        }}
                      >
                        {code}
                      </Box>
                    )}

                    {/* Action Buttons */}
                    <Tooltip title={copied ? "Copied!" : "Copy to Clipboard"}>
                      <IconButton
                        sx={{
                          color: copied ? "#10b981" : "#9ca3af",
                          transition: "color 0.3s",
                          "&:hover": {
                            color: copied ? "#059669" : "#4b5563"
                          }
                        }}
                        onClick={handleCopy}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={isEditing ? "Save" : "Edit Code"}>
                      <IconButton
                        sx={{
                          color: "#6b7280",
                          transition: "color 0.3s",
                          "&:hover": {
                            color: "#374151"
                          }
                        }}
                        onClick={handleBlur}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
            <IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}
              >
                <MenuItem onClick={() => setOpenModal(true)}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
              {currentAdmin?.profilePic ? (
                <Box
                  onClick={handleClick}
                  component="img"
                  borderRadius="50%"
                  // border="1px solid #38B65B"
                  height={45}
                  width={45}
                  src={imgSrc}
                  alt="avatar"
                />
              ) : (
                <Avatar onClick={handleClick} height={45} width={45}>
                  {getInitials(currentAdmin?.fullName)}
                </Avatar>
              )}
            </IconButton>
            <EditAdmin
              isSuperAdmin={currentAdmin?.adminUserId}
              role={currentAdmin?.userType}
              editData={currentAdmin}
              open={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          ".MuiDrawer-paper": {
            background: "#14142B",
            color: "#9CA3AF"
          }
        }}
      >
        <DrawerHeader sx={{ minHeight: "90px" }}>
          <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            width="100%"
            gap="10px"
            margin="-6px"
          >
            <img
              src="/images/threepillars.png"
              alt="logo"
              style={{ height: "40px" }}
            />
            {open ? (
              <Typography fontSize="large" color="#38B65B" variant="bold">
                Three Pillars Method
              </Typography>
            ) : (
              <></>
            )}
          </Box>

          <IconButton
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{
              marginLeft: "20px",
              position: "absolute",
              padding: 0,
              right: -12, // Position based on drawer state
              transform: "translateY(-50%)",
              backgroundColor: "#1F2937",
              "&:hover": {
                backgroundColor: "#333333"
              },
              top: "5.5%",
              color: "#38B65B",
              border: "1px solid #38B65B",
              zIndex: 1300
            }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{ marginTop: "20px" }}>
          {menuItems.map(({ text, icon }) => (
            <ListItem key={text} sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => {
                  setIsAddingMeal(false);
                  setIsAddingTraining(false);
                  handleTabChange(text);
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    position: "relative",
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    ...(text === activeTab &&
                      text === "Programs" && {
                        svg: {
                          fill: "#38B65B"
                        }
                      }),
                    ...(text === activeTab &&
                      text !== "Programs" && {
                        svg: {
                          fill: "none",
                          path: {
                            stroke: "#38B65B"
                          }
                        }
                      })
                  }}
                >
                  {text === "MOD" && !open && counts ? (
                    <Badge
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#FFF",
                          backgroundColor: "#F6541C",
                          position: "absolute", // Set badge position as absolute
                          top: open ? 0 : 2, // Adjust based on drawer state
                          left: open ? "60px" : "20px" // Adjust badge positioning for closed state
                        }
                      }}
                      badgeContent={counts}
                    >
                      {icon}
                    </Badge>
                  ) : text === "Admin Portal" &&
                    !open &&
                    currentAdmin?.checkNotesReminder ? (
                    <Badge
                      variant="dot"
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#FFF",
                          backgroundColor: "#F6541C",
                          left: "unset",
                          minWidth: "11px",
                          height: "11px",
                          right: "-7px",
                          borderRadius: "50%"
                        }
                      }}
                    >
                      {icon}
                    </Badge>
                  ) : (
                    icon
                  )}
                </ListItemIcon>
                {text === "MOD" && open && counts ? (
                  <Badge
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#FFF",
                        backgroundColor: "#F6541C",
                        left: "25px"
                      }
                    }}
                    badgeContent={counts}
                  >
                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        ...(text === activeTab && {
                          color: "#38B65B"
                        })
                      }}
                    />
                  </Badge>
                ) : text === "Admin Portal" &&
                  open &&
                  currentAdmin?.checkNotesReminder ? (
                  <Badge
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#FFF",
                        backgroundColor: "#F6541C",
                        left: "unset",
                        minWidth: "11px",
                        height: "11px",
                        right: "-7px",
                        borderRadius: "50%"
                      }
                    }}
                  >
                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        ...(text === activeTab && {
                          color: "#38B65B"
                        })
                      }}
                    />
                  </Badge>
                ) : (
                  <ListItemText
                    primary={text}
                    sx={{
                      opacity: open ? 1 : 0,
                      ...(text === activeTab && {
                        color: "#38B65B"
                      })
                    }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {!currentAdmin?.adminUserId && (
        <ConnectProfileModal admin={currentAdmin} />
      )}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader
          style={{
            height: "95px"
          }}
        />
        <Box sx={{ background: "#f3f4f6" }} p={4}>
          {!currentAdmin?.adminUserId ? adminRenderContent() : renderContent()}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
