import React from "react";
import {
  Card,
  Box,
  Typography,
} from "@mui/material";
import useThemeColor from "../../utils/useTheme";

const CircularGauge = ({
  diaryId,
  levelId,
  strokeColor,
  title,
  value,
  nonEditable = false,
  levelName,
  maxValue,
  dayName,
  openWeightModal,
  setIsRefreshNeeded,
  setOpenWeightModal,
  width = 350, // Dynamic width
  height = 250 // Dynamic height
}) => {
  // Calculate the percentage based on value and maxValue
  const percentage = (value / maxValue) * 100;
  const styles = useThemeColor();
  // Calculate radius and center based on dynamic width and height
  const radius = (width - 50) / 2; // Adjust padding as needed
  const centerX = width / 2;
  const centerY = width / 2;
  const strokeWidth = levelName === "level2" ? 17 : 20; // Increase stroke width to make the arc thicker

  // Define start and end angles for a semicircle
  const startAngle = -200;
  const endAngle = 20;

  // Calculate the angle for the current value
  const valueAngle = Math.min(
    startAngle + (endAngle - startAngle) * (percentage / 100),
    endAngle
  );

  // Create the SVG arc path for the background and progress
  const createArcPath = (start, end) => {
    const rad = (angle) => (angle * Math.PI) / 180;

    const startPoint = {
      x: centerX + radius * Math.cos(rad(start)),
      y: centerY + radius * Math.sin(rad(start))
    };

    const endPoint = {
      x: centerX + radius * Math.cos(rad(end)),
      y: centerY + radius * Math.sin(rad(end))
    };

    const largeArcFlag = Math.abs(end - start) > 180 ? 1 : 0;

    return `
      M ${startPoint.x} ${startPoint.y}
      A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endPoint.x} ${endPoint.y}
    `;
  };

  // Define arcs
  const backgroundArc = createArcPath(startAngle, endAngle);
  const progressArc = createArcPath(startAngle, valueAngle);

  return (
    <Card
      sx={{
        boxShadow: "none !important",
        padding: 4,
        width,
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box position="relative">
        <svg width={width} height={height}>
          {/* Background Arc */}
          <path
            d={backgroundArc}
            fill="none"
            stroke={"#D1D5DB"}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />

          {/* Progress Arc */}
          <path
            d={progressArc}
            fill="none"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            style={{
              transition: "stroke-dashoffset 0.5s ease"
            }}
          />
        </svg>

        {/* Center Text */}
        <Box
          position="absolute"
          top="70%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            textAlign: "center"
          }}
        >
          <Typography
            fontSize={"16px"}
            color={styles.color}
            sx={{ opacity: 0.75 }}
          >
            {title}
          </Typography>
          <Typography fontSize={"24px"} color={styles.color} fontWeight="bold">
            {value}g
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            justifyContent={"center"}
          >
            <Typography fontSize={"15px"} color="#8E8E8E">
              out of
            </Typography>
            <Typography fontSize={"15px"} color="#38B65B">
              {maxValue}g
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CircularGauge;
