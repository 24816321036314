import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  superAdminLogin,
  superAdminLogout,
  adminRegister,
  editUserProfile,
  addNewUserAdmin,
  fetchUser,
  verifyIdentity,
  updateAdminDetails,
  getDashboardData,
  fetchAllCards,
  getSubAdminDashboardData
} from "../../api/authApi";

// Thunks for handling async actions
export const loginUser = createAsyncThunk(
  "adminauth/login",
  async (credentials, thunkAPI) => {
    try {
      const response = await superAdminLogin(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  "adminauth/register",
  async (userData, thunkAPI) => {
    try {
      const response = await adminRegister(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyIdentityUser = createAsyncThunk(
  "auth/verifyIdentity",
  async (sessionData, thunkAPI) => {
    try {
      const response = await verifyIdentity(sessionData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "adminauth/getCurrentUser",
  async (_, thunkAPI) => {
    try {
      const response = await fetchUser();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDashboardDataAdmin = createAsyncThunk(
  "adminauth/getDashboardDataAdmin",
  async (date, thunkAPI) => {
    try {
      const response = await getDashboardData(date);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSubAdminDashboardDetails = createAsyncThunk(
  "adminauth/getSubAdminDashboardDetails",
  async (date, thunkAPI) => {
    try {
      const response = await getSubAdminDashboardData();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addNewUser = createAsyncThunk(
  "adminauth/addNewUser",
  async (userData, thunkAPI) => {
    try {
      const response = await addNewUserAdmin(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editUser = createAsyncThunk(
  "auth/editUser",
  async ({ formData, userId }, thunkAPI) => {
    try {
      const response = await editUserProfile(formData, userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAdminData = createAsyncThunk(
  "auth/updateAdminData",
  async (data, thunkAPI) => {
    try {
      const response = await updateAdminDetails(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "adminauth/logout",
  async (_, thunkAPI) => {
    try {
      const response = await superAdminLogout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchUserCards = createAsyncThunk(
  "auth/getCards",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAllCards();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  admin:
    JSON.parse(
      ["undefined", "null", undefined, null].includes(
        localStorage.getItem("admin")
      )
        ? null
        : localStorage.getItem("admin")
    ) || null,
  isAuthenticated: !!localStorage.getItem("adminToken"),
  dashboardData: {},
  subAdminBillingDetails: {},
  cards: [],
  loading: false,
  error: null
};

const adminauthSlice = createSlice({
  name: "adminauth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.admin = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(verifyIdentityUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyIdentityUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(verifyIdentityUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.admin = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getDashboardDataAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardDataAdmin.fulfilled, (state, action) => {
        state.dashboardData = action.payload;
        state.loading = false;
      })
      .addCase(getDashboardDataAdmin.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getSubAdminDashboardDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubAdminDashboardDetails.fulfilled, (state, action) => {
        state.subAdminBillingDetails = action.payload;
        state.loading = false;
      })
      .addCase(getSubAdminDashboardDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserCards.fulfilled, (state, action) => {
        state.cards = action.payload.cards;
      })
      // Logout
      .addCase(logoutUser.fulfilled, (state) => {
        state.admin = null;
        state.isAuthenticated = false;
        state.loading = false;
      });
  }
});

export default adminauthSlice.reducer;
