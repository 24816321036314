import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminLogin from "./components/Auth/AdminLogin";
import Register from "./components/Auth/Register";
import PrivateRoute from "./components/Auth/PrivateRoute";
import PartnersSignup from "./components/Auth/PartnersSignup";
import AdminDashboard from "./components/Auth/AdminDashboard";
import NotFound from "./components/NotFound";
import VerifyIdentity from "./components/Auth/VerifyIdentity";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<AdminLogin />} /> */}
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/partners" element={<Register />} />
        <Route path="/partners/signup" element={<PartnersSignup />} />
        <Route path="/verify-identity" element={<VerifyIdentity />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
