import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Alert,
  IconButton,
  Typography,
  Chip,
  Divider,
  Card
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  LocalOffer as CouponIcon,
  AccessTime as TimeIcon,
  AttachMoney as MoneyIcon
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  addCoupon,
  deleteCoupon,
  editCoupon,
  fetchAllCoupons
} from "../../features/users/userSlice";
import { selectUsersCoupons } from "../../features/users/userSelectors";

const CouponManagement = () => {
  const coupons = useSelector(selectUsersCoupons);
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });
  const [formData, setFormData] = useState({
    couponCode: "",
    amount: "",
    waivingLogging: "",
    trainingAccess: {
      level1: false,
      level2: false,
      level3: false
    }
  });
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllCoupons());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
    setEditMode(false);
    resetForm();
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      couponCode: "",
      amount: "",
      waivingLogging: "",
      trainingAccess: {
        level1: false,
        level2: false,
        level3: false
      }
    });
    setEditMode(false);
    setEditId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckboxChange = (level) => {
    setFormData((prev) => ({
      ...prev,
      trainingAccess: {
        ...prev.trainingAccess,
        [level]: !prev.trainingAccess[level]
      }
    }));
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSubmit = async () => {
    try {
      console.log({
        editId: editId ? addCoupon : editCoupon
      });
      const { payload } = !editId
        ? await dispatch(addCoupon(formData))
        : await dispatch(editCoupon({ ...formData, id: editId }));

      if (!payload?.couponCode) throw new Error(payload?.message);

      showSnackbar(`Coupon ${editMode ? "updated" : "created"} successfully`);
      handleClose();
      dispatch(fetchAllCoupons());
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  const handleEdit = (coupon) => {
    setFormData({
      couponCode: coupon.couponCode,
      amount: coupon.amount,
      waivingLogging: coupon.waivingLogging,
      trainingAccess: coupon.trainingAccess
    });
    setEditMode(true);
    setEditId(coupon._id);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      dispatch(deleteCoupon({ id }));

      showSnackbar("Coupon deleted successfully");
      setTimeout(() => dispatch(fetchAllCoupons()), 2000);
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  return (
    <Box sx={{ p: 3, backgroundColor: "#f5f5f5" }}>
      <Card sx={{ mb: 4, p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "end", mb: 2 }}>
          {/* <CouponIcon sx={{ fontSize: 40, color: "primary.main", mr: 2 }} />
          <Typography variant="h4" component="h1" color="primary.main">
            Coupon Management
          </Typography> */}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
            sx={{
              ml: "auto",
              borderRadius: 2,
              textTransform: "none",
              px: 3,
              py: 1
            }}
          >
            Add New Coupon
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />

        <TableContainer
          component={Paper}
          elevation={3}
          sx={{ borderRadius: 2 }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "primary.main" }}>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CouponIcon sx={{ mr: 1 }} /> Coupon Code
                  </Box>
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <MoneyIcon sx={{ mr: 1 }} /> Amount
                  </Box>
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TimeIcon sx={{ mr: 1 }} /> Waiving Logging
                  </Box>
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Training Access
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons?.length ? (
                coupons.map((coupon) => (
                  <TableRow
                    key={coupon._id}
                    sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                  >
                    <TableCell>
                      <Chip
                        label={coupon.couponCode}
                        color="primary"
                        variant="outlined"
                        sx={{ fontWeight: "bold" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography color="success.main" fontWeight="bold">
                        ${coupon.amount}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={<TimeIcon />}
                        label={`${coupon.waivingLogging} days`}
                        color="secondary"
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        {Object.entries(coupon.trainingAccess)
                          .filter(([_, value]) => value)
                          .map(([key]) => (
                            <Chip
                              key={key}
                              label={key.replace("level", "Level ")}
                              size="small"
                              color="info"
                            />
                          ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleEdit(coupon)}
                        color="primary"
                        sx={{ "&:hover": { backgroundColor: "primary.light" } }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(coupon._id)}
                        color="error"
                        sx={{ "&:hover": { backgroundColor: "error.light" } }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="body1" color="text.secondary">
                      No coupons found. Create your first coupon!
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 24,
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "primary.main",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: 2
          }}
        >
          <CouponIcon />
          {editMode ? "Edit Coupon" : "Add New Coupon"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              pt: 3,
              display: "flex",
              flexDirection: "column",
              gap: 3
            }}
          >
            <TextField
              name="couponCode"
              label="Coupon Code"
              value={formData.couponCode}
              onChange={handleInputChange}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <CouponIcon sx={{ mr: 1, color: "primary.main" }} />
                )
              }}
            />
            <TextField
              name="amount"
              label="Amount"
              type="number"
              value={formData.amount}
              onChange={handleInputChange}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <MoneyIcon sx={{ mr: 1, color: "primary.main" }} />
                )
              }}
            />
            <TextField
              name="waivingLogging"
              label="Waiving Logging (Days)"
              type="number"
              value={formData.waivingLogging}
              onChange={handleInputChange}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <TimeIcon sx={{ mr: 1, color: "primary.main" }} />
                )
              }}
            />
            <Card variant="outlined" sx={{ p: 2 }}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                Training Access Levels
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.trainingAccess.level1}
                      onChange={() => handleCheckboxChange("level1")}
                      color="primary"
                    />
                  }
                  label="Level 1"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.trainingAccess.level2}
                      onChange={() => handleCheckboxChange("level2")}
                      color="primary"
                    />
                  }
                  label="Level 2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.trainingAccess.level3}
                      onChange={() => handleCheckboxChange("level3")}
                      color="primary"
                    />
                  }
                  label="Level 3"
                />
              </FormGroup>
            </Card>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ borderRadius: 2 }}
          >
            {editMode ? "Update Coupon" : "Create Coupon"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          variant="filled"
          elevation={6}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CouponManagement;
