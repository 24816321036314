import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Avatar,
  Chip,
  linearProgressClasses,
  Accordion,
} from "@mui/material";
import { ReactComponent as ChefIcon } from "../../assets/chef.svg";
import { ReactComponent as Lvl1Icon } from "../../assets/lvl1.svg";
import { ReactComponent as Lvl2Icon } from "../../assets/lvl2.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DateRangePicker from "../Extra/DateRangePicker";
import DiaryTracker from "../Extra/DiaryTracker";
import { ReactComponent as TrophyIcon } from "../../assets/goal-trophy.svg";
import {
  selectAllDiaryOutOf800g,
  selectAllDiaryOutOfProtein,
  selectAllDiaryStats
} from "../../features/diary/diarySelectors";
import { getCurrentUserDiaryStats } from "../../features/diary/diarySlice";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import getInitials from "../../utils/getInitials";
import useSignedUrl from "../../utils/useSignedUrl";
import useThemeColor from "../../utils/useTheme";
import { options800gms, optionsProteins } from "../../utils/mockWeightOptions";
import { convertItemQuantityToGrams } from "../../utils/weightConvert";

const ImageItem = ({ img }) => {
  const imgSrc = useSignedUrl(
    img,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return (
    <Box
      component="img"
      borderRadius="50%"
      height={100}
      width={100}
      src={imgSrc}
      alt="avatar"
    />
  );
};

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, activeColor, background }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: background
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: activeColor
    }
  })
);

const StatsListModal = ({ open, setOpen }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState("7days");
  const dispatch = useDispatch();
  const allStats = useSelector(selectAllDiaryStats);
  const total800g = useSelector(selectAllDiaryOutOf800g);
  const totalProteins = useSelector(selectAllDiaryOutOfProtein);

  const levelName = open?.diaryLevelName;
  const handleClose = () => {
    setOpen({});
    setValue("7days");
  };
  useEffect(() => {
    if (
      open.userId &&
      ((value === "custom" && startDate && endDate) || value !== "custom")
    )
      dispatch(
        getCurrentUserDiaryStats({
          userId: open.userId,
          timeFrame: value,
          date: dayjs().format("YYYY-MM-DD"),
          ...(value === "custom" && {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: dayjs(endDate).format("YYYY-MM-DD")
          })
        })
      );
  }, [dispatch, value, open.userId, startDate, endDate]);

  const calculateAverage800gms = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalEntry800g || 0),
      0
    );
    return {
      average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOf800g || total800g
    };
  }, [allStats, total800g]);

  const calculateAverageProtein = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );

    return {
      average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOfProtein || totalProteins
    };
  }, [allStats, totalProteins]);
  const styles = useThemeColor();

  return (
    <>
      {/* Modal */}
      <Dialog
        sx={{
          width: "100%",
          ".MuiPaper-root": {
            maxWidth: "100%"
          }
        }}
        open={Object.keys(open).length}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Stats
          </Typography>
          {/* <Button onClick={handleClose} style={{ float: "right" }}>
            Close
          </Button> */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box display="flex" gap="20px" padding={2}>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              px={2}
              py={3}
              borderRadius={2}
              alignItems="center"
              sx={{ background: "#F3F4F6", maxWidth: "270px" }}
            >
              {open?.profilePic ? (
                <ImageItem img={open?.profilePic} />
              ) : (
                <Avatar sx={{ width: 100, height: 100, mr: 1, fontSize: 29 }}>
                  {open.fullName
                    ? getInitials(open.fullName)
                    : getInitials(open.displayName)}
                </Avatar>
              )}
              <Typography variant="h6" fontWeight={600} fontSize={16}>
                {open?.fullName}
              </Typography>
              <Chip
                label={"User"}
                sx={{
                  backgroundColor: "#FCECD3",
                  fontSize: "12px",
                  color: "#F1B44C"
                }}
              />
              {open?.approvedMealsCount || open?.completedLevel?.length ? (
                <Box
                  display="flex"
                  mt={2}
                  ml={2}
                  gap={2}
                  alignItems="center"
                  overflow="auto"
                  flexWrap="wrap" // Allow wrapping to the next line
                  justifyContent="flex-start" // Align items to the left
                  sx={{
                    "::-webkit-scrollbar": {
                      display: "none"
                    }
                  }}
                >
                  {open?.approvedMealsCount ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <ChefIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Chef
                      </Typography>
                      <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.approvedMealsCount}
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.includes("level1") ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl1Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 1
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.includes("level2") ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl2Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 2
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.includes("level3") ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl3Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 3
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={3}>
              {/* Dropdown & Custom Date Picker */}
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={"column"}
              >
                <Typography variant="h6" fontWeight="bold">
                  Average for Set Time Period
                </Typography>
                <FormControl
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    marginTop: "15px",
                    svg: { fill: "#1C1C1C" },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#F5F5F5" },
                      "&:hover fieldset": { borderColor: "#1C1C1C" },
                      "&.Mui-focused fieldset": { borderColor: "#1C1C1C" }
                    }
                  }}
                >
                  <Select
                    labelId="range-label"
                    name="range"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    sx={{ color: "#1C1C1C" }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="7days">7 Days</MenuItem>
                    <MenuItem value="30days">30 Days</MenuItem>
                    <MenuItem value="custom">Custom Time</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {value === "custom" && (
                <DateRangePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              )}

              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  borderRadius: "16px"
                }}
              >
                <DiaryTracker
                  title={"#800gChallenge"}
                  nonEditable
                  levelName={levelName}
                  strokeColor={"#F6541C"}
                  value={calculateAverage800gms?.average}
                  maxValue={calculateAverage800gms?.total}
                  height={levelName === "level2" ? 210 : 200}
                  width={levelName === "level2" ? 300 : 280}
                />
                {levelName === "level2" && (
                  <DiaryTracker
                    title={"Protein"}
                    nonEditable
                    levelName={levelName}
                    strokeColor={"#368AD0"}
                    value={calculateAverageProtein?.average}
                    maxValue={calculateAverageProtein?.total}
                    height={levelName === "level2" ? 210 : 250}
                    width={levelName === "level2" ? 300 : 350}
                  />
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                mb={2}
                sx={{
                  overflowY: "auto",
                  maxHeight: "295px",
                  "::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
              >
                {allStats?.map((item, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === index} // Controls whether the Accordion is open
                    onChange={() =>
                      setExpanded(expanded === index ? false : index)
                    }
                    sx={{
                      ":before": {
                        position: "relative"
                      },
                      boxShadow: "none"
                    }}
                  >
                    <Box
                      width="600px"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      sx={{
                        background: styles.modBackgroundBlocks,
                        borderRadius: "16px",
                        p: 3
                      }}
                    >
                      {/* Date and Goal Achieved */}
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" gap="10px" alignItems="center">
                          <Typography fontWeight={600} fontSize={"18px"}>
                            {dayjs(item?.currentDayDate).format("MMMM D")}
                          </Typography>
                          {+item.totalEntry800g >= +item.outOf800g &&
                            (levelName === "level2"
                              ? +item.totalProtein >= +item.outOfProtein
                              : true) && (
                              <Box
                                display="flex"
                                gap="4px"
                                alignItems="center"
                                sx={{
                                  background: "#F1B44C",
                                  px: 1,
                                  border: `1px solid ${styles.color}`,
                                  borderRadius: "100px"
                                }}
                              >
                                <TrophyIcon />
                                <Typography color="#14142B" fontSize={"12px"}>
                                  Goal Achieved
                                </Typography>
                              </Box>
                            )}
                        </Box>

                        {/* Expand More Icon */}
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() =>
                            setExpanded(expanded === index ? false : index)
                          }
                        >
                          {expanded === index ? (
                            <ExpandLess sx={{ cursor: "pointer" }} />
                          ) : (
                            <ExpandMore sx={{ cursor: "pointer" }} />
                          )}
                        </IconButton>
                      </Box>

                      {/* Content Section */}
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography
                          fontWeight={600}
                          color="#F6541C"
                          fontSize="16px"
                        >
                          800g
                        </Typography>
                        <Box>
                          <Typography fontWeight={600} fontSize="16px">
                            {item.totalEntry800g || 0}/{item.outOf800g}g
                          </Typography>
                        </Box>
                      </Box>
                      <BorderLinearProgress
                        activeColor="#F6541C"
                        background={"#D1D5DB"}
                        variant="determinate"
                        value={
                          ((item.totalEntry800g || 0) >= item.outOf800g
                            ? 1
                            : (item.totalEntry800g || 0) / item.outOf800g) * 100
                        }
                      />

                      {/* Protein Progress for Level 2 */}
                      {levelName === "level2" && (
                        <>
                          <Box
                            display="flex"
                            mt={1}
                            justifyContent="space-between"
                          >
                            <Typography
                              fontWeight={600}
                              color="#368AD0"
                              fontSize="16px"
                            >
                              Protein
                            </Typography>
                            <Box>
                              <Typography fontWeight={600} fontSize="16px">
                                {item.totalProtein || 0}/{item.outOfProtein}g
                              </Typography>
                            </Box>
                          </Box>
                          <BorderLinearProgress
                            activeColor="#368AD0"
                            background={"#D1D5DB"}
                            variant="determinate"
                            value={
                              ((item.totalProtein || 0) >= item.outOfProtein
                                ? 1
                                : (item.totalProtein || 0) /
                                  item.outOfProtein) * 100
                            }
                          />
                        </>
                      )}

                      {/* Collapsible Content */}
                      {expanded === index && (
                        <Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mt={2}
                            borderTop={"1px solid lightgray"}
                            pt={2}
                          >
                            <Typography
                              fontWeight={600}
                              color="#F6541C"
                              fontSize="16px"
                            >
                              800g
                            </Typography>
                            <Box>
                              <Typography fontWeight={600} fontSize="16px">
                                {item.totalEntry800g || 0}/{item.outOf800g}g
                              </Typography>
                            </Box>
                          </Box>
                          {item?.entry800g?.map((data) => (
                            <Box
                              py={0.4}
                              width={"100%"}
                              display="flex"
                              justifyContent="space-between"
                              key={data.key}
                            >
                              <Box>
                                <Typography
                                  fontSize={13}
                                  color={styles.color}
                                  textTransform={"capitalize"}
                                >
                                  {options800gms?.find(
                                    (option) => option.value === data.name
                                  )?.label ?? data.name}
                                </Typography>
                                <Typography
                                  fontSize={10}
                                  color={styles.disabledColor}
                                >
                                  {data.time}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems={"center"}>
                                <Typography fontSize={13} fontWeight={400}>
                                  {convertItemQuantityToGrams(data)?.quantity}g
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                          {levelName === "level2" && (
                            <>
                              <Box
                                display="flex"
                                mt={1}
                                justifyContent="space-between"
                              >
                                <Typography
                                  fontWeight={600}
                                  color="#368AD0"
                                  fontSize="16px"
                                >
                                  Protein
                                </Typography>
                                <Box>
                                  <Typography fontWeight={600} fontSize="16px">
                                    {item.totalProtein || 0}/{item.outOfProtein}
                                    g
                                  </Typography>
                                </Box>
                              </Box>
                              {item?.protein?.map((data) => (
                                <Box
                                  width={"100%"}
                                  display="flex"
                                  py={0.4}
                                  justifyContent="space-between"
                                  key={data.key}
                                >
                                  <Box>
                                    <Typography
                                      fontSize={13}
                                      color={styles.color}
                                      textTransform={"capitalize"}
                                    >
                                      {optionsProteins?.find(
                                        (option) => option.value === data.name
                                      )?.label ?? data.name}
                                    </Typography>
                                    <Typography
                                      fontSize={10}
                                      color={styles.disabledColor}
                                    >
                                      {data.time}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" alignItems={"center"}>
                                    <Typography fontSize={13} fontWeight={400}>
                                      {
                                        convertItemQuantityToGrams(data)
                                          ?.quantity
                                      }
                                      g
                                    </Typography>
                                  </Box>
                                </Box>
                              ))}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Accordion>
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StatsListModal;
