import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getUsersByType,
  deleteUserHandler,
  getAllCoupons,
  deleteCouponHandler,
  addnewCoupon,
  editCouponHandler
} from "../../api/userApi";

// Thunk for getting all users with pagination
export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async ({ currentTab, page, limit, searchValue }, thunkAPI) => {
    try {
      const response = await getAllUsers(currentTab, page, limit, searchValue);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchAllCoupons = createAsyncThunk(
  "users/fetchAllCoupons",
  async (_, thunkAPI) => {
    try {
      const response = await getAllCoupons();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addCoupon = createAsyncThunk(
  "users/addCoupon",
  async (data, thunkAPI) => {
    try {
      const response = await addnewCoupon(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCoupon = createAsyncThunk(
  "users/deleteCoupon",
  async (data, thunkAPI) => {
    try {
      const response = await deleteCouponHandler(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const editCoupon = createAsyncThunk(
  "users/editCoupon",
  async (data, thunkAPI) => {
    try {
      const response = await editCouponHandler(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (userId, thunkAPI) => {
    try {
      const response = await deleteUserHandler(userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Thunk for getting users by type with pagination
export const fetchUsersByType = createAsyncThunk(
  "users/fetchUsersByType",
  async ({ page, limit, searchValue }, thunkAPI) => {
    try {
      const response = await getUsersByType(page, limit, searchValue);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state for users
const initialState = {
  users: [],
  coupons: [],
  total: 0,
  occupiedSeats: 0,
  page: 0,
  limit: 10,
  pendingMealsCount: 0,
  loading: false,
  error: null
};

// Slice for users
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setPendingMealsCount: (state) => {
      state.pendingMealsCount = state.pendingMealsCount - 1;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch all users
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.users = action.payload.users; // Assuming the API response has a `data` array
        state.total = action.payload.total; // Assuming the API response has a `total` field
        state.pendingMealsCount = action.payload.pendingMealsCount;
        state.loading = false;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Fetch users by type
      .addCase(fetchUsersByType.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsersByType.fulfilled, (state, action) => {
        state.users = action.payload.users; // Assuming the API response has a `data` array
        state.total = action.payload.total; // Assuming the API response has a `total` field
        state.loading = false;
        state.occupiedSeats = action.payload.occupiedSeats;
      })
      .addCase(fetchUsersByType.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload.coupons; // Assuming the API response has a `data` array
      });
  }
});

export const { setPage, setLimit, setPendingMealsCount } = usersSlice.actions;

export default usersSlice.reducer;
