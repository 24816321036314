import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import usersReducer from "../features/users/userSlice";
import modReducer from "../features/mod/modSlice";
import trainingReducer from "../features/training/trainingSlice";
import diaryReducer from "../features/diary/diarySlice";

export const store = configureStore({
  reducer: {
    adminauth: authReducer,
    users: usersReducer,
    mod: modReducer,
    training: trainingReducer,
    diary: diaryReducer
  }
});
